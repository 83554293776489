<template>
    <div>
        <h1>Work in Progress!</h1>
    </div>
</template>

<script>
    export default {
        name: "ProgressPage"
    }
</script>

<style></style>